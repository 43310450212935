.site-content.phoseason {
  // color: white;
  // background-color: rgb(18,18,18);
  // background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23bdbdbd' fill-opacity='0.08'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  // transition: background-color 0.3s;
}
.site-content.phoseason {
  transition: background-color 0.3s;
}

.phoseason {
  .landing-page {
    // background-image: url(https://images.unsplash.com/photo-1542319920155-a236a55f1a97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80);
    background-position: 0% 50%;

  }


  a.page-link {
    color: white;
    border-bottom-color: black;
    &:hover, &.active {
      border-bottom-color: white;
    }
  }

  .landing-page {
    .text {
      color: white;
      background: rgba(0,0,0,0.7);
    }
  }


}
$darkCheckoutPaneColor: #185500;
.phoseason {
  .product-wrapper {
    color: white;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: white;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    color: black;
    padding: 1em 0.5em 2em 0.5em;
    background: #fffdd2;
  }

  .footer {
    background: #111;
  }
}
