@import url("https://use.typekit.net/uxz5oay.css");


$red: #fc5412;
$green: #24862f;
$bg: white;
$sigFont: omnes-semicond, sans-serif;
$displayFont: omnes-pro, sans-serif;

.site-content {
  background-color: black;
  // background: url(http://www.redbancosdealimentos.org/upload/2017/11/11/wallpaper-texture-background-wood-dark-hd-picture-image-dark-wood-background-l-a021a5a8ac662dd1.jpg);
  color: white;
}
.phoseason {
  font-family: $sigFont;
  .category-link-item {
    color: white !important;
  }
  .menu-nav-wrapper {
    background-color: black;
    a {
      color: white;
    }
  }
  .menu-story {
    margin-top: 0;
  }
  .cart.minimized {
    // background: url(http://mexcocinacafe.com/wp-content/uploads/2014/05/wood.jpg) no-repeat;
    padding: 0.4em 0.5em 0.1em 0.5em;
    background: black;
    color: black;
    background-size: cover;
    border-bottom: none;

  }
  a.checkout-button.button {
    // font-family: 'Bungee Inline', sans-serif;
    background: $red;
    // font-weight: 400;
    box-shadow: none;
    // border: 2px solid #5a7e2a;
  }

  .category-name .text {
    position: relative;
    // text-transform: lowercase;
    font-family: $displayFont;
    font-weight: 900;
    font-style: italic;
    font-size: 1.8em;
    background: none;
    // color: black;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    // color: whtie;
    // text-shadow: 0px 1px 0 black;

  }
  .landing-page-wrapper {
    position: relative;
  }
  .landing-page {
    // height: 540px;
    min-height: 400px;
    // background: url(https://live.staticflickr.com/8700/17006269720_f57926e07c_h.jpg);
    background: url(https://afag.imgix.net/pho-season/premium-oxtail-pho.jpg?w=1200&auto=format&fm=jpg&bg=222);
    background-size: cover;
    background-position: 50% 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text-new {
      color: white;
    }
    // .text {
    //   padding: 0 0.5em;
    //   font-weight: 400;
    //   color: white;
    //   font-size: 2em;
    //   align-items: center;
    //   .address {
    //     font-size: 0.8em;
    //   }
    //   form {
    //     font-size: 0.7em;
    //     input[type="email"] {
    //       margin-right: 0.15em;
    //     }
    //   }
    //   .logo-inline {
    //     position: relative;
    //     top: 6px;
    //     height: 55px;
    //   }
    //   @media screen and (max-width: 650px) {
    //     .logo-inline {
    //       height: 45px;
    //     }
    //     font-size: 1.4em;
    //   }
    // }
  }
  .food-name {
    // text-transform: lowercase;
    // text-transform: capitalize;
  }

  .landing-page {
    text-align: center;
    font-family: $displayFont;
    font-weight: 900;
    font-style: italic;
    z-index: 2;

    width: 100%;

    margin: 0 auto;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: black;
    h2 {
      // color: black;
    }
    .catch-phrase {
      font-size: 2em;
    }
    @media screen and (max-width: 650px) {
      font-size: 1em;
    }
  }

  .landing-section-link {
    text-transform: uppercase;
  }
  .pages-nav {
    font-family: $sigFont;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    background: black;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  $borderWidth: 4px;
  $paddingY: 0.35em;
  a.page-link {
    text-decoration: none;
    color: white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    border-bottom: $borderWidth solid black;
    &:hover, &.active {
      border-bottom: $borderWidth solid $red;
    }
  }

  a.order-online-link {
    text-transform: uppercase;
    // font-family: 'Helvetica', sans-serif;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 0.5em 0;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $red;
    position: relative;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .ordering-header-wrapper {
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,f_auto,w_1280/v1544211832/danstuff/Menu.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 250px;
    padding: 1em 0;
    color: white;
    font-size: 1.5em;
    font-weight: 900;
    .text {
      padding: 1em;
      background: rgba(0,0,0,0.5);
      max-width: 400px;
    }
  }
  .landing-page-wrapper {
    position: relative;
    .special {
      font-size: 1.5em;
      max-width: 700px;
      padding: 0.4em;
      margin: 0 auto;
      font-family: $sigFont;
      font-weight: 600;
    }
  }
  .landing-page {
    // height: 540px;
  }
  // .fire-bg {
  //   filter: blur(1.5px);
  //   height: 100%;
  //   background: url(http://afag.imgix.net/pho-season/pho-full.gif?frame=1-80&gif-q=60&w=400&sat=30&blur=15) no-repeat;
  //   background-size: cover;
  //   background-position: center;
  //
  // }
  // .video-bg {
  //   // filter: brightness(0.5);
  //   position: relative;
  //   width: auto;
  //   min-width: 100%;
  //
  //   height: auto;
  //   min-height: 100%;
  //
  //   background-size: cover;
  // }
  // video {
  //   display: block;
  //
  // }
  // .video-container {
  //
  //   width: 100%;
  //   // height: 100%;
  //   height: 540px;
  //   @media screen and (max-width: 650px) {
  //     height: 340px;
  //   }
  //   // max-width: 960px;
  //   overflow: hidden;
  //   // position: absolute;
  //   top: 0;
  //   right: 0;
  // }
  // .fire-gif {
  //   height: 540px;
  //   @media screen and (max-width: 650px) {
  //     height: 340px;
  //   }
  // }
}
